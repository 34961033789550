import React from 'react';

import {
  ButtonIconAdvertisingSection
} from '@lerna-monorepo/common/components/buttonIconAdvertisingSection/buttonIconAdvertisingSection.component';
import { PhotoHeader } from '@lerna-monorepo/common/components/photoHeader/photoHeader.component';
import { CaseStudyItem } from '@lerna-monorepo/common/interfaces/caseStudy.types';
import { Color } from '@lerna-monorepo/common/theme';
import { useScrollToSection } from '@lerna-monorepo/common/utils/useScrollToSection';

import { CaseStudyListPageData } from './caseStudyList.types';
import { CaseStudySectionContainer, HalfBox, LocalCaseStudySection } from './caseStudyList.styled';

const CaseStudyListPage: React.FC<CaseStudyListPageData> = (pageData: CaseStudyListPageData) => {
  const { data, caseStudies, caseStudyBasePath, currentLanguage, basePaths } = pageData.pageContext;
  const {
    listHeader,
    buttonIconAdvertisingSection,
    highlightedCaseStudy,
    highlightedCaseStudyButtonLabel,
  } = data;
  const { sectionRef, handleScroll } = useScrollToSection({ block: 'center' }); 
  const filteredCaseStudies = caseStudies.filter(
    (caseStudyItem: CaseStudyItem) => caseStudyItem.language.slug === currentLanguage
  );
  const customizedCaseStudies = filteredCaseStudies.map((caseStudyItem: CaseStudyItem) =>
    ({ ...caseStudyItem, post_name: caseStudyItem.slug, post_title: caseStudyItem.title })
  );

  return (
    <>
      <PhotoHeader
        subheaderContent={highlightedCaseStudy.acf.country}
        headerContent={highlightedCaseStudy.post_title}
        buttonLabel={highlightedCaseStudyButtonLabel}
        background={highlightedCaseStudy.acf.image}
        handleScroll={handleScroll}
      />
      <CaseStudySectionContainer>
        <LocalCaseStudySection
          ref={sectionRef}
          header={listHeader}
          caseStudyBoxes={customizedCaseStudies}
          currentLanguage={currentLanguage}
          boxesBaseUrl={caseStudyBasePath}
          highlightedCaseStudyTitle={highlightedCaseStudy.post_title}
          basePaths={basePaths}
        />
        <HalfBox />
      </CaseStudySectionContainer>
      <ButtonIconAdvertisingSection
        icon={buttonIconAdvertisingSection.icon}
        textArray={buttonIconAdvertisingSection.textArray}
        buttonLabel={buttonIconAdvertisingSection.buttonLabel}
        buttonLink={buttonIconAdvertisingSection.buttonLink}
        backgroundColor={Color.white}
      />
    </>
  );
};

export default CaseStudyListPage;
